import { GemPad, KDG, Nexus, WeLabs, Zenix } from './assets';

export const LAUNCHPADS = [
  {
    image: GemPad,
    title: 'GemPad',
    url: 'https://gempad.app/presale/0xa8c5F2C668d8726c3526fC62BDc0E73bd696dfc5?network=BNB_Smart_Chain',
  },
  {
    image: WeLabs,
    title: "WeLabs",
    url: "https://welabs.app/projectdetails/48/upcomming"
  },
  {
    image: Zenix,
    title: 'Zenix',
    url: 'https://www.zenixapp.pro/pools/6m5p19nJRm',
  },
  {
    image: Nexus,
    title: 'Nexus',
    url: 'https://shorturl.at/19hsc',
  },
  {
    image: KDG,
    title: 'KDG',
    url: 'https://kingdomstarter.io/launchpad/upcoming/CratD2C',
  },
];

