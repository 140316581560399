import { Card, Divider, Stack, Typography } from '@mui/material';
import { useBreakpoints } from 'shared';

export const Price = () => {
  const { isDownSmLayout } = useBreakpoints();

  return (
    <Card
      sx={{
        py: '20px',
        mt: 3,
        mb: { xs: 4, md: 10 },
      }}
    >
      <Stack direction={{ sm: 'row' }} gap={{ xs: 1.5, md: 0 }}>
        <Stack alignItems="center" justifyContent="center" flex={1}>
          <Typography variant="h4">$0.75</Typography>
          <Typography variant="h6" className="accent center">
            IEO Rounds Starting Price
          </Typography>
        </Stack>
        <Divider
          orientation={isDownSmLayout ? 'horizontal' : 'vertical'}
          sx={{
            height: 'auto',
          }}
        />
        <Stack alignItems="center" justifyContent="center" flex={1}>
          <Typography variant="h4">$0.80</Typography>
          <Typography variant="h6" className="secondary center">
            Listing Price
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};
