import { Box } from '@mui/material';

import { Intro, IdoLive, Partners, Price, UseCases } from './ui';

export const Home = () => {
  return (
    <Box>
      <Intro />
      <Partners />
      <UseCases />
      <IdoLive />
      <Price />
    </Box>
  );
};
