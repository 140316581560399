import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { buyTokens, getCratPrice } from './thunks';
import { BuyData, Pack } from './types';

export type CrowdsaleState = {
  cratAvailableAmount: string;
  cratTotalCount: number;
  cratPrice: string;
  cratBalance: string;
  usdtBalance: string;
  packs: Array<Pack>;

  currentBuyData: BuyData | null;
};

const initialCrowdsaleState: CrowdsaleState = {
  cratAvailableAmount: '0',
  cratTotalCount: 20_000_000,
  cratPrice: '0',
  cratBalance: '0',
  usdtBalance: '0',
  packs: [],

  currentBuyData: null,
};

const crowdsaleSlice = createSlice({
  name: 'crowdsale',
  initialState: initialCrowdsaleState,
  reducers: {
    updateCrowdsaleState: (state: CrowdsaleState, action: PayloadAction<Partial<CrowdsaleState>>) => ({
      ...state,
      ...action.payload,
    }),

    updateBuyData: (state: CrowdsaleState, action: PayloadAction<BuyData | null>) => {
      state.currentBuyData = action.payload;
    },
  },

  extraReducers(builder) {
    builder.addCase(buyTokens.fulfilled, (state) => {
      state.currentBuyData = null;
    });
    builder.addCase(getCratPrice.fulfilled, (state, { payload }) => {
      if (!payload) return state;
      return {
        ...state,
        cratPrice: payload,
      };
    });
  },
});

export const { reducer } = crowdsaleSlice;
export const { actions } = crowdsaleSlice;
