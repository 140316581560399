import { alpha } from '@mui/system';

export const COLOR_PRIMARY = '#E0E0E0';
export const COLOR_SECONDARY = '#868388';
export const COLOR_ACCENT = '#B4EB56';
export const COLOR_STROKE = '#4B484D';
export const COLOR_STROKE_SECONDARY = '#444046';
export const COLOR_BG = '#0F0A13';
export const COLOR_BG_ALPHA_74 = alpha('#000000', 0.74);
export const COLOR_BG_ALPHA_31 = alpha('#000000', 0.31);
export const COLOR_BG_BLACK = '#06050A';
export const COLOR_BG_BLACK_2 = '#282828';
export const COLOR_BG_BLACK_3 = '#121212';
export const COLOR_BG_BLACK_4 = '#1D1921';
export const COLOR_WH = '#FFFFFF';
export const COLOR_BLACK = '#000000';

export const COLOR_PURPURE = '#9B4DFF';
export const COLOR_RED = '#FF4040';
export const COLOR_ACCENT_DARK = '#87AB49';
export const COLOR_DARK = '#161517';
export const COLOR_LIGHT_GRAY_INPUT = '#D8D8D8';

export const COLOR_GRAY = '#7A7A7A';
export const COLOR_GRAY_2 = '#353337';
export const COLOR_GRAY_3 = '#B7B8BA';
export const COLOR_GRAY_4 = '#707070';
export const COLOR_GRAY_5 = '#766F7B';
export const COLOR_GRAY_LIGHT = '#E8E8E8';
export const COLOR_GRAY_LIGHT_2 = '#E7E7E7';
export const COLOR_GRAY_LIGHT_3 = '#F8F8F8';
export const COLOR_GRAY_LIGHT_4 = '#E6E8EC';

export const COLOR_TEXT_GRAY = '#949494';
