const GET_CROWDSALE_DATA = 'crowdsale/getCrowdsaleData';
const BUY_TOKENS = 'crowdsale/buyTokens';
const APPROVE = 'crowdsale/approve';
const GET_CRAT_PRICE = 'crowdsale/cratPrice';

export const actionTypes = {
  GET_CROWDSALE_DATA,
  BUY_TOKENS,
  APPROVE,
  GET_CRAT_PRICE,
} as const;
